import {
  Badge,
  Box,
  IconButton,
  Popover,
  styled,
  Typography,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useNotificationHub } from '../../contexts/NotificationHubContext';
import { useSnackbar } from 'notistack';
import { memo, useCallback, useEffect, useState } from 'react';
import {
  NotificationsContextProvider,
  useNotifications,
} from './NotificationsContext';
import {
  UserNotificationEnum,
  UserNotificationItemDTO,
} from '../../shared/services/models/UserNotificationModel';

import DeleteIcon from '@mui/icons-material/ClearOutlined';
import { momentsAgo, toDateISO } from '../../shared/DateUtil';
import { useNavigate } from 'react-router-dom';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'read',
})<{ read: boolean }>(({ theme, read }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  cursor: 'pointer',
  '& div.MuiTypography-root': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '0.8rem',
    fontWeight: read ? 300 : 600,
  },
}));

const NotificationItem = memo(
  ({
    item,
    onDelete,
    onOpen,
  }: {
    item: UserNotificationItemDTO;
    onDelete: (item: UserNotificationItemDTO) => void;
    onOpen: (item: UserNotificationItemDTO) => void;
  }) => {
    const navigate = useNavigate();
    useEffect(() => {
      if (!item.read) {
        setTimeout(() => onOpen(item), 3000);
      }
    }, [item, onOpen]);

    const doNavigate = useCallback(() => {
      const data = JSON.parse(item.jsonData || '{}');
      if (
        item.typeId === UserNotificationEnum.BillApproved ||
        item.typeId === UserNotificationEnum.BillCreated
      ) {
        navigate('/bills');
      } else if (item.typeId === UserNotificationEnum.TimesheetSubmitted) {
        navigate('/approvals');
      } else if (
        item.typeId === UserNotificationEnum.TimesheetPendingSubmission
      ) {
        navigate('/timesheets', { state: data });
      } else if (item.typeId === UserNotificationEnum.ForgotPassword) {
        navigate('/profile');
      }
    }, [item.jsonData, item.typeId, navigate]);

    return (
      <StyledBox read={item.read}>
        <Box maxWidth={180}>
          <Typography component='div' onClick={doNavigate}>
            {item.message}
          </Typography>
          <Typography variant={'caption'} color={'primary.light'}>
            {momentsAgo(toDateISO(item.timestamp))}
          </Typography>
        </Box>

        <IconButton size='small' onClick={() => onDelete(item)}>
          <DeleteIcon />
        </IconButton>
      </StyledBox>
    );
  }
);

function NotificationHubButton() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { items, markRead, deleteItem, doForceRefresh } = useNotifications();
  const { serverData } = useNotificationHub();

  const showMessage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onDelete = useCallback(
    (item: UserNotificationItemDTO) => {
      deleteItem!(item.notificationId);
    },
    [deleteItem]
  );

  const onOpen = useCallback(
    (item: UserNotificationItemDTO) => {
      markRead!(item.notificationId);
    },
    [markRead]
  );

  useEffect(() => {
    if (serverData) {
      doForceRefresh!();
    }
  }, [doForceRefresh, serverData]);

  const open = Boolean(anchorEl);

  return (
    <>
      {items.length > 0 ? (
        <>
          <IconButton onClick={showMessage}>
            <Badge badgeContent={items.length} color='info'>
              {serverData ? <NotificationsActiveIcon /> : <NotificationsIcon />}
            </Badge>
          </IconButton>
          {anchorEl && (
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {items.map((a) => (
                <NotificationItem
                  key={a.notificationId}
                  item={a}
                  onDelete={onDelete}
                  onOpen={onOpen}
                />
              ))}
            </Popover>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export function NotificationHub() {
  const { enqueueSnackbar } = useSnackbar();
  const { serverData, setServerData } = useNotificationHub();

  useEffect(() => {
    if (serverData?.dataType === 'message') {
      enqueueSnackbar(serverData?.message, {
        variant: serverData?.messageType ?? 'info',
        autoHideDuration: 5000,
      });

      setTimeout(() => setServerData!(null), 5000);
    }
  }, [serverData, enqueueSnackbar, setServerData]);

  return (
    <NotificationsContextProvider>
      <NotificationHubButton />
    </NotificationsContextProvider>
  );
}
