export interface UserNotificationItemDTO {
  notificationId: string;
  userId: number;
  typeId: number;
  message: string;
  read: boolean;
  timestamp: string;
  jsonData: string;
}

export enum UserNotificationEnum {
  BillCreated = 19002,
  BillApproved = 19003,
  TimesheetSubmitted = 19004,
  ForgotPassword = 19005,
  DocSubmitted = 19006,
  TimesheetPendingSubmission = 19007,
}
